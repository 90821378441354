import React from 'react';
import { css } from '@emotion/core';
import { Desktop, Tablet } from '../../constants/responsive';

// 渐变背景块
const GradientBackground = ({
  marginTop = [0, 0, 0],
  toTop = false,
  height = [612, 357, 303],
  background = `linear-gradient(
    to bottom,
    rgba(242, 242, 242, 0),
    #f2f2f2
  )`,
  ...rest
}) => {
  return (
    <div
      css={css`
        width: 100%;
        background: ${background};
        height: ${height[0]}px;
        margin-top: ${marginTop[0]}px;
        ${Tablet} {
          margin-top: ${marginTop[1]}px;
          height: ${height[1]}px;
        }
        ${Desktop} {
          margin-top: ${marginTop[2]}px;
          height: ${height[2]}px;
        }
      `}
      {...rest}
    ></div>
  );
};

export default GradientBackground;

import React, { Children } from 'react';
import Container from '../Container';
import BannerBand from '../../svgs/banner-band.svg';
import Check from '../../svgs/institutions/check.svg';
import { Desktop, Tablet } from '../../constants/responsive';
import { css } from '@emotion/core';
import { FormattedHTMLMessage, useIntl } from 'gatsby-plugin-intl';
import InstitutionsAnimation from '../InstitutionsAnimation';
import { useAppContext } from '../../context/AppContext';
import Blank from '../Blank';

const ToolIcon = ({ tool, width }) => {
  const intl = useIntl();
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: ${tool === 'commodities' ? '12px' : '0px'};
      `}
    >
      <img
        src={`/institutions/strategy-library/${tool}.png`}
        alt=''
        css={css`
          // width: 57px;
          // height: 58px;
          width: ${width || '61px'};
          ${Tablet} {
            // width: 61px;
            // height: 58px;
          }
          ${Desktop} {
            // width: 61px;
            // height: 70px;
          }
        `}
      ></img>
      <div
        css={css`
          color: #333333;
          text-align: center;
          font-size: 18px;
          line-height: 1.33;
          margin-top: ${tool === 'commodities' ? '20px' : '10px'};
          ${Tablet} {
            margin-top: 15px;
            font-size: 18px;
            line-height: 1.33;
          }
          ${Desktop} {
            font-size: 22px;
          }
        `}
      >
        {intl.formatMessage({ id: `institutions.strategy-library.${tool}` })}
      </div>
    </div>
  );
};

const InstitutionsStrategyLibrary = ({
  section = 'advisory',
  ...marginTopCss
}) => {
  const intl = useIntl();

  return (
    <>
      {/* 灰色块占满屏幕宽度 */}
      <div
        css={css`
          width: 100%;
          background-color: #f2f2f2;
          display: flex;
          justify-content: center;
          height: 516px;
          ${Tablet} {
            height: 402px;
          }
          ${Desktop} {
            height: 395px;
          }
        `}
      >
        {/* 控制最大宽度 */}
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 100%;
            padding: 102px 29px 0 30px;
            ${Tablet} {
              max-width: 768px;
              padding: 100px 103px 0 103px;
            }
            ${Desktop} {
              max-width: 1440px;
              padding: 100px 231px 0 231px;
            }
          `}
        >
          {/* 【title】 */}
          <div
            css={css`
              color: #333333;
              font-weight: 500;
              text-align: center;
              // white-space: nowrap;
              font-size: 18px;
              line-height: 1.22;
              margin-bottom: 50px;
              width: 293px;
              ${Tablet} {
                font-size: 24px;
                line-height: 1.38;
                margin-bottom: 50px;
                width: 504px;
              }
              ${Desktop} {
                font-size: 28px;
                margin-bottom: 67px;
                width: 772px;
              }
            `}
          >
            {intl.formatMessage({ id: `institutions.strategy-library.title` })}
          </div>
          {/* 【策略库工具图标】- WEB、IPAD端 */}
          <div
            css={css`
              display: none;
              ${Tablet} {
                display: flex;
                justify-content: space-between;
                width: 524px;
                align-items: flex-end;
              }
              ${Desktop} {
                width: 978px;
              }
            `}
          >
            <ToolIcon tool='equities' />
            <ToolIcon tool='income' />
            <ToolIcon tool='commodities' width='79px' />
            <ToolIcon tool='REITs' />
          </div>
          {/* 【策略库工具图标】- MOBILE端 */}
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              // width: 100%;
              width: 289px;
              ${Tablet} {
                display: none;
              }
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                justify-content: space-between;
              `}
            >
              <ToolIcon tool='equities' />
              <Blank height={[50, 0, 0]} />
              <ToolIcon tool='REITs' />
            </div>

            <div
              css={css`
                display: flex;
                flex-direction: column;
                justify-content: space-between;
              `}
            >
              <ToolIcon tool='commodities' />
              <Blank height={[50, 0, 0]} />
              <ToolIcon tool='income' />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InstitutionsStrategyLibrary;

import React, { Children } from 'react';
import Container from '../Container';
import BannerBand from '../../svgs/banner-band.svg';
import Check from '../../svgs/institutions/check.svg';
import { Desktop, Tablet } from '../../constants/responsive';
import { css } from '@emotion/core';
import { FormattedHTMLMessage, useIntl, Link } from 'gatsby-plugin-intl';
import InstitutionsAnimation from '../InstitutionsAnimation';
import { useAppContext } from '../../context/AppContext';
import Blank from '../Blank';
import { graphql, useStaticQuery } from 'gatsby';

const ServiceCard = ({ img, name }) => {
  const intl = useIntl();
  const nameMap = {
    fundsuper: 'fund-super',
    advisory: 'robo-advisor',
    smartfund: 'smart-fund',
  };
  return (
    <div
      css={css`
        border-radius: 15px;
        background-color: #f2f2f2;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 325px;
        height: 177px;
        ${Tablet} {
          width: 508px;
          height: 235px;
        }
        ${Desktop} {
          width: 508px;
          height: 235px;
        }
      `}
    >
      {/* 图 */}
      <div
        css={css`
          width: 74px;
          height: 74px;
          border-radius: 100px;
          box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.08);
          background: url(${img}) center/auto 100% no-repeat border-box
            border-box;
          background-size: cover;

          border: 5px solid #ffffff;
          margin-top: -37px;
          margin-bottom: 18px;
          ${Tablet} {
            width: 115px;
            height: 115px;
            border: 8px solid #ffffff;
            margin-top: -57.5px;
            margin-bottom: 6px;
          }
          ${Desktop} {
            width: 115px;
            height: 115px;
            border: 8px solid #ffffff;
            margin-top: -57.5px;
            margin-bottom: 6px;
          }
        `}
      ></div>
      <div
        css={css`
          color: #333333;
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          margin-bottom: 7px;
          ${Tablet} {
            font-size: 24px;
            line-height: 1.38;
          }
          ${Desktop} {
            font-size: 24px;
            line-height: 1.38;
          }
        `}
      >
        {intl.formatMessage({
          id: `institutions.service.${nameMap[name]}.title`,
        })}
      </div>
      <div
        css={css`
          color: #333333;
          font-weight: normal;
          font-size: 14px;
          line-height: 1.29;
          margin-bottom: 13px;
          ${Tablet} {
            font-size: 20px;
            line-height: 1.65;
            margin-bottom: 20px;
          }
          ${Desktop} {
            font-size: 20px;
            line-height: 1.75;
            margin-bottom: 20px;
          }
        `}
      >
        {intl.formatMessage({
          id: `institutions.service.${nameMap[name]}.description`,
        })}
      </div>
      <Link to={`/product/institutions/${name}`}>
        <div
          css={css`
            border-radius: 23.5px;
            background-color: #ee2f79;
            text-align: center;
            color: #ffffff;
            font-weight: 500;
            width: 118px;
            height: 31px;
            font-size: 14px;
            line-height: 31px;
            ${Tablet} {
              width: 184px;
              height: 47px;

              font-size: 16px;
              line-height: 47px;
            }
            ${Desktop} {
              width: 184px;
              height: 47px;

              font-size: 16px;
              line-height: 47px;
            }
          `}
        >
          {intl.formatMessage({
            id: `institutions.service.${nameMap[name]}.btn`,
          })}
        </div>
      </Link>
    </div>
  );
};

const InstitutionsService = ({ section = 'advisory', ...marginTopCss }) => {
  const intl = useIntl();
  const data = useStaticQuery(graphql`
    query {
      fundsuper: file(
        relativePath: { eq: "institutions/service/fund-super.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      advisory: file(
        relativePath: { eq: "institutions/service/robo-advisor.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      smartfund: file(
        relativePath: { eq: "institutions/service/smart-fund.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);
  // 其他服务机构卡片的显示顺序
  const otherService = {
    advisory: ['fundsuper', 'smartfund'],
    fundsuper: ['smartfund', 'advisory'],
    smartfund: ['advisory', 'fundsuper'],
  };
  return (
    <>
      <Container
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 0 25px;
          ${Tablet} {
            padding: 0 130px;
          }
          ${Desktop} {
            padding: 0 67px;
          }
        `}
      >
        {/* title */}
        <div
          css={css`
            font-size: 24px;
            font-weight: 500;
            line-height: 1.33;
            color: #333333;
            margin-bottom: 100px;
            text-align: center;
            ${Tablet} {
              font-size: 36px;
              line-height: 1.25;
              margin-bottom: 150px;
            }
            ${Desktop} {
              font-size: 36px;
              line-height: 0.92;
              margin-bottom: 150px;
            }
          `}
        >
          {intl.formatMessage({ id: `institutions.service.title` })}
        </div>
        {/* cards */}
        <div
          css={css`
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            ${Tablet} {
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
            }
            ${Desktop} {
              width: 100%;
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              justify-content: space-between;
            }
          `}
        >
          <ServiceCard
            img={data[otherService[section][0]].childImageSharp.fluid.src}
            name={otherService[section][0]}
          />
          <Blank height={[100, 150, 0]} />
          <ServiceCard
            img={data[otherService[section][1]].childImageSharp.fluid.src}
            name={otherService[section][1]}
          />
        </div>
      </Container>
    </>
  );
};

export default InstitutionsService;

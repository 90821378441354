import React, { Children } from 'react';
import Container from '../Container';
import BannerBand from '../../svgs/banner-band.svg';
import Check from '../../svgs/institutions/check.svg';
import { Desktop, Tablet } from '../../constants/responsive';
import { css } from '@emotion/core';
import { FormattedHTMLMessage, useIntl } from 'gatsby-plugin-intl';
import InstitutionsAnimation from '../InstitutionsAnimation';
import { useAppContext } from '../../context/AppContext';
import Blank from '../../components/Blank';

const AdvantageCard = ({ section, advantage }) => {
  const intl = useIntl();

  return (
    <div
      css={css`
        :last-child {
          margin-bottom: 0;
        }
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 50px;
        ${Tablet} {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          margin-bottom: 50px;
        }
        ${Desktop} {
          display: flex;
          margin-bottom: 55px;
        }
      `}
    >
      {/* 图标 */}
      <img
        css={css`
          width: 42px;
          margin-top: 12px;
          ${Tablet} {
            margin-top: 0px;
            width: 39px;
          }
          ${Desktop} {
            margin-top: 12px;
            width: 42px;
          }
        `}
        alt=''
        src={`/institutions/algo-introduction/${advantage}.png`}
      />

      {/* 文字 */}
      <div
        css={css`
          text-align: center;
          margin-top: 16px;
          ${Tablet} {
            text-align: left;
            margin-left: 30px;
            margin-top: 0;
          }
          ${Desktop} {
            margin-left: 30px;
          }
        `}
      >
        <div
          css={css`
            color: #000000;
            font-size: 18px;
            font-weight: 500;
            line-height: 20px;
            margin-bottom: 12px;
            ${Tablet} {
              font-size: 18px;
              font-weight: 500;
              line-height: 20px;
              margin-bottom: 5px;
            }
            ${Desktop} {
              font-size: 20px;
              font-weight: 500;
              line-height: 40px;
              margin-bottom: 5px;
            }
          `}
        >
          {intl.formatMessage({
            id: `institutions.${section}.algo-introduction.advantage.${advantage}.title`,
          })}
        </div>
        <div
          css={css`
            color: #000000;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            ${Tablet} {
              font-size: 14px;
              font-weight: 400;
              line-height: 22px;
            }
            ${Desktop} {
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
            }
          `}
        >
          {intl.formatMessage({
            id: `institutions.${section}.algo-introduction.advantage.${advantage}.text`,
          })}
        </div>
      </div>
    </div>
  );
};

const InstitutionsAlgorithmIntroduction = ({
  section = 'advisory',
  ...marginTopCss
}) => {
  const intl = useIntl();
  const advantageMap = {
    advisory: ['macro', 'plug', 'big-data'],
    fundsuper: ['wide', 'know'],
    smartfund: ['multi', 'deep'],
  };
  // 底部【联系我们】的组件实例对象
  const { institutionsFormRef } = useAppContext();
  return (
    <>
      <div>
        <Container
          css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
            ${Tablet} {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 768px;
            }
            ${Desktop} {
              display: flex;
              flex-direction: row;
              align-items: center;
              padding: 0;
              width: 1203px;
            }
          `}
        >
          {/* 字-介绍 */}
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 293px;
              text-align: center;
              ${Tablet} {
                width: 662px;
                align-items: center;
              }
              ${Desktop} {
                width: 573px;
                align-items: flex-start;
              }
            `}
          >
            <div
              css={css`
                color: #333333;
                font-weight: 500;
                font-size: 24px;
                line-height: 1.33;
                margin-bottom: 20px;
                ${Tablet} {
                  font-size: 36px;
                  line-height: 1.33;
                  margin-bottom: 20px;
                }
                ${Desktop} {
                  font-size: 36px;
                  margin-bottom: 35px;
                  text-align: left;
                }
              `}
            >
              {intl.formatMessage({
                id: `institutions.${section}.algo-introduction.title`,
              })}
            </div>
            <div
              css={css`
                color: #333333;
                font-weight: normal;
                font-size: 14px;
                line-height: 1.33;
                margin-bottom: 30px;
                text-align: center;
                ${Tablet} {
                  width: 393px;
                  font-size: 18px;
                  line-height: 24px;
                  margin-bottom: 20px;
                  text-align: center;
                }
                ${Desktop} {
                  width: 555px;
                  font-size: 22px;
                  margin-bottom: 39px;
                  text-align: left;
                  line-height: 1.11;
                }
              `}
            >
              {intl.formatMessage({
                id: `institutions.${section}.algo-introduction.text`,
              })}
            </div>
            <div
              css={css`
                border-radius: 23.5px;
                background-color: #ee2f79;
                text-align: center;
                width: 184px;
                height: 47px;
                cursor: pointer;
                line-height: 47px;
                font-size: 16px;
                font-weight: bold;
                color: #ffffff;
              `}
              onClick={() => {
                const el = document.getElementById('___gatsby');
                el.scrollTo({
                  top: institutionsFormRef.current.offsetTop,
                  behavior: 'smooth',
                });
              }}
            >
              {intl.formatMessage({
                id: `institutions.${section}.algo-introduction.btn`,
              })}
            </div>
          </div>
          {/* blank */}
          <div
            css={css`
              ${Desktop} {
                width: 80px;
              }
            `}
          ></div>
          <Blank height={[100, 100, 0]} />
          {/* 优势点 */}
          <div
            css={css`
              overflow: hidden;
              background-color: #ffffff;
              box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.08);
              border-radius: 15px;
              padding: 50px 17px 50px 18px;
              width: 319px;
              ${Tablet} {
                padding: 50px 36px 40px 40px;
                width: 670px;
              }
              ${Desktop} {
                padding: 50px 52px 40px 50px;
                width: 550px;
              }
            `}
          >
            {advantageMap[section].map((key) => {
              return (
                <AdvantageCard
                  key={`${section}-${key}`}
                  section={section}
                  advantage={key}
                />
              );
            })}
          </div>
        </Container>
      </div>
    </>
  );
};

export default InstitutionsAlgorithmIntroduction;
